import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const HomeIcon = props => (
  <SvgIcon {...props}>
  <path d="M22.3,8.9l-8.8-7.4c-1-0.9-2.5-0.9-3.6,0L1.7,8.9c-0.6,0.6-0.2,1.7,0.7,1.7h0.1c0.6,0,1,0.5,1,1v8.9c0,1.5,1.2,2.7,2.7,2.7
	H18c1.5,0,2.7-1.2,2.7-2.7v-8.9c0-0.6,0.5-1,1-1C22.6,10.6,23,9.5,22.3,8.9z M7.5,13.7c-0.1,0-0.2,0-0.3-0.1C7.1,13.5,7,13.4,7,13.3
	c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0V13c0,0,0,0,0,0c0-0.1,0-0.2,0.1-0.3c0-0.2,0.1-0.5,0.2-0.7c0-0.1,0.1-0.3,0.2-0.4
	c0.1-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.1-0.3,0.2-0.4c0.2-0.4,0.5-0.8,0.9-1.1C8.8,9.3,9,9.2,9.1,9.1C9.3,9,9.6,8.9,9.8,8.7
	c0.2-0.1,0.3-0.1,0.5-0.2c0.2-0.1,0.5-0.1,0.7-0.1c0.2,0,0.3,0,0.5,0c0.1,0,0.1,0,0.2,0l0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.3,0,0.4,0
	c0.1,0,0.2,0,0.3,0c0.2,0,0.4,0.1,0.5,0.1c0.2,0.1,0.4,0.1,0.6,0.2c0.3,0.1,0.5,0.2,0.8,0.4c0.1,0.1,0.3,0.2,0.4,0.3
	c0.1,0.1,0.3,0.2,0.4,0.4c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.1,0.1,0.2,0.1,0.4c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.2,0.2-0.4,0.3
	c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.3,0-0.4,0c-0.3,0-0.5-0.1-0.8-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.2-0.2-0.3-0.3
	c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2-0.1-0.4-0.2-0.6-0.2c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0c-0.2,0-0.3,0-0.4,0.1
	c-0.2,0-0.4,0.1-0.6,0.2c-0.1,0.1-0.3,0.2-0.4,0.3C10,10.7,9.9,10.8,9.9,11c0,0.1-0.1,0.2-0.1,0.3c0,0.3,0,0.5,0.1,0.7
	c0.1,0.1,0.1,0.2,0.2,0.3c0.2,0.2,0.4,0.3,0.6,0.4c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.2,0.4
	c0,0.1,0,0.2,0,0.3c0,0.1,0,0.1,0,0.2c-0.1,0.2-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.2-0.5,0.2c-0.1,0-0.3,0-0.4,0
	c-0.2,0-0.5-0.1-0.7-0.2c-0.3-0.1-0.5-0.2-0.7-0.3c-0.1,0-0.2-0.1-0.2-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0
	c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.2,0C7.7,13.6,7.6,13.7,7.5,13.7z M16.9,14.3c0,0.1,0,0.2,0,0.3c-0.1,0.3-0.2,0.7-0.3,1
	c-0.1,0.3-0.2,0.7-0.4,1c-0.1,0.3-0.3,0.5-0.5,0.7c-0.1,0.2-0.3,0.3-0.4,0.5c-0.2,0.2-0.4,0.4-0.7,0.5c-0.1,0.1-0.2,0.1-0.3,0.2
	c-0.2,0.1-0.3,0.1-0.5,0.2c-0.2,0.1-0.5,0.2-0.7,0.2c-0.1,0-0.2,0-0.4,0.1c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1,0H12
	c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0c-0.2,0-0.5-0.1-0.7-0.1c-0.3-0.1-0.5-0.2-0.7-0.3c-0.3-0.1-0.5-0.3-0.8-0.4
	c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.2-0.1-0.2-0.2c-0.2-0.2-0.3-0.4-0.4-0.6C8,16.9,8,16.8,8,16.7c0-0.1,0-0.2,0-0.3
	c0.1-0.2,0.2-0.3,0.3-0.4c0.2-0.1,0.3-0.2,0.5-0.2c0.1,0,0.2,0,0.3,0c0.2,0,0.4,0.1,0.6,0.2c0.2,0.1,0.3,0.2,0.4,0.3
	c0,0.1,0.1,0.1,0.1,0.2c0.1,0.1,0.2,0.3,0.4,0.3c0.2,0.1,0.5,0.2,0.7,0.3c0.1,0,0.2,0,0.3,0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.2,0
	c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3-0.1c0.2,0,0.4-0.1,0.6-0.2c0.2-0.1,0.3-0.2,0.5-0.4c0.2-0.2,0.3-0.4,0.3-0.6c0-0.1,0-0.2,0-0.3
	c0-0.1-0.1-0.2-0.1-0.4c-0.1-0.1-0.1-0.2-0.2-0.3c-0.2-0.2-0.3-0.3-0.6-0.4c-0.1-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.3-0.1-0.4-0.2
	c-0.1-0.1-0.2-0.2-0.2-0.4c0-0.2,0-0.4,0.1-0.6c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0
	c0.2,0,0.4,0.1,0.7,0.2c0.3,0.1,0.6,0.2,0.9,0.4c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.3,0,0.4,0c0.1,0,0.1,0,0.2,0
	c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.1,0,0.2C16.9,14.1,16.9,14.2,16.9,14.3z"
	/>
  </SvgIcon>
);

export default HomeIcon;