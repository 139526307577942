import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const SignupIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M13.6,4l-2.8-3.6c-0.3-0.4-1-0.4-1.3,0L6.7,4c-0.4,0.6,0,1.3,0.7,1.3H13C13.7,5.4,14.1,4.6,13.6,4z M11.6,12.2V5.3H8.8v6.9
	c0,0.8,0.7,1.3,1.3,1.3l0,0C10.9,13.5,11.6,13,11.6,12.2z M23.2,3.8C22.5,4.3,22,4.7,21.7,5c-2,1.8-3.7,4-4.1,6.8
	c-0.2,1.1-0.3,2.1-0.5,3.2c-0.1,1-0.3,1.8-0.5,2.7c-0.1,0.5-0.2,1-0.4,1.3c-1,2.7-2.9,4.1-5.7,4.7c-4.5,0.8-8.8-2.4-9.6-6.9
	C0.4,14.1,1.5,11.7,3,9.7c0.6-0.9,1.4-1.6,2.2-2.2v5c0,1.3,1.1,2.4,2.4,2.4H13c1.3,0,2.4-1.1,2.4-2.4V3.7c1.8-0.3,3.6-0.4,5.7-0.2
	C21.8,3.5,22.8,3.6,23.2,3.8z"/>
    </SvgIcon>
)

export default SignupIcon;