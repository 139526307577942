import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ResetIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M9.9,17.4h6.7c0,0.1-0.1,0.3-0.2,0.4c-1,2.9-3.1,4.4-6,5C5.6,23.6,1,20.2,0.2,15.4c-0.5-3,0.6-5.5,2.2-7.6
	c1.9-2.7,4.7-4.1,7.6-5c4.2-1.3,7.3-1.9,11.7-1.6c0.5,0.1,1.6,0.2,2,0.4c-0.7,0.5-1.2,0.9-1.6,1.3C21.1,4,20,5.2,19.2,6.5H9.9
	C8,6.5,6.5,8.1,6.5,10v4C6.5,15.8,8,17.4,9.9,17.4z M12.9,7.9c2,0.2,2.3,0.8,1.8,1.7c-0.5,0.8-2.1,1.1-2.7,2s-0.2,2.3-0.2,2.3
	c0.2,0.6,0.7,0.6,1.2,0.2c0.2-0.2,0.2-0.2,0-1.5c0-0.9,2.7-1,3.3-2.4c0.6-1.4-0.2-3-3.4-3.1c-3.2-0.1-3.3,2-3.3,2
	c-0.1,0.7,1.3,1.1,2,1C11.6,10.1,10.8,7.8,12.9,7.9z M12.5,15.3c-0.4,0-0.8,0.3-0.8,0.7c0,0.4,0.2,0.8,0.7,0.8
	c0.4,0,0.8-0.2,0.8-0.7C13.3,15.7,13,15.2,12.5,15.3z"/>
    </SvgIcon>
)

export default ResetIcon;