import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const LoginIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M17.5,14v-4c0-1.9-1.5-3.4-3.4-3.4H4.8C4,5.2,2.9,4,1.7,2.9C1.3,2.5,0.8,2.1,0.1,1.6c0.4-0.2,1.5-0.3,2-0.4
	c4.4-0.3,7.5,0.3,11.7,1.6c3,0.9,5.7,2.3,7.6,5c1.6,2.1,2.8,4.7,2.2,7.6c-0.8,4.8-5.4,8.2-10.2,7.3c-3-0.6-5-2.1-6-5
	c-0.1-0.1-0.1-0.3-0.2-0.4h6.7C16,17.4,17.5,15.8,17.5,14z M12.5,15.2l2.9-2.2c0.4-0.3,0.4-0.8,0-1.1l-2.9-2.3
	c-0.4-0.4-1.1,0-1.1,0.5v4.5C11.4,15.3,12.1,15.5,12.5,15.2z M4.8,12.5L4.8,12.5c0,0.5,0.4,1.1,1.1,1.1h5.6v-2.2H5.9
	C5.2,11.3,4.8,11.9,4.8,12.5z"/>
    </SvgIcon>
)

export default LoginIcon;