import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const LogoutIcon = (props) => (
    <SvgIcon {...props}>
        <path d="M9.9,17.4h6.7c0,0.1-0.1,0.3-0.2,0.4c-1,2.9-3.1,4.4-6,5C5.6,23.6,1,20.2,0.2,15.4c-0.5-3,0.6-5.5,2.2-7.6
	c1.9-2.7,4.7-4.1,7.6-5c4.2-1.3,7.3-1.9,11.7-1.6c0.5,0.1,1.6,0.2,2,0.4c-0.7,0.5-1.2,0.9-1.6,1.3C21.1,4,20,5.2,19.2,6.5H9.9
	C8,6.5,6.5,8.1,6.5,10v4C6.5,15.8,8,17.4,9.9,17.4z M16,15.2l2.9-2.2c0.4-0.3,0.4-0.8,0-1.1L16,9.6c-0.4-0.4-1.1,0-1.1,0.5v4.5
	C14.9,15.3,15.6,15.5,16,15.2z M8.3,12.5L8.3,12.5c0,0.5,0.4,1.1,1.1,1.1H15v-2.2H9.4C8.7,11.3,8.3,11.9,8.3,12.5z"/>
    </SvgIcon>
)

export default LogoutIcon;